.items {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9rem;
    overflow: hidden;
    padding: 0.2rem;
    position: relative;
  }
  
  .item {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.4rem;
    display: block;
    margin: 0;
    padding: 0.2rem 0.4rem;
    text-align: left;
    width: 100%;
  
  }

.item.is-selected {
  /* border-color: #000 !important; */
  outline: solid
}
  