.wiki-container {
  /* padding: 1rem; */
  padding: 0;
  padding-top: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 650px) {
  .wiki-container {
    padding: 0;
    padding-top: 40px;
  }
}

.wiki-container > * {
  padding-top: 20px !important;
}
/* 
.emoji-mart-emoji, .emoji-mart-emoji > * {
  vertical-align: text-bottom;
  padding: 0 !important;
  margin: 0 !important;
} */
